<template>
  <BaseModal
    ref="modal"
    title="Biedingen"
    max-width="tw-max-w-5xl"
    @hide="onHide"
  >
    <DataTable
      :loading="loading"
      :headers="headers"
      :infinite-scroll="true"
      v-bind="bids"
      @infiniteLoad="infiniteLoad"
    >
      <template #item-property="{ item }">
        <EntityHoverable
          :value="{
            type: 'property',
            id: item.property.id,
            reference: item.property.reference
          }"
          @clicked="hide"
        />
      </template>
      <template #item-amount="{ item }">
        {{ currency(item.amount) }}
      </template>
      <template #item-comment=" { item }">
        <div v-if="item.comment">{{ item.comment }}</div>
        <div v-if="item.loan_amount">
          Te ontlenen bedrag: {{ currency(item.loan_amount) }}
        </div>
        <div v-if="item.loan_approval_term">
          Goedkeuringstermijn: {{ item.loan_approval_term }} dagen
        </div>
      </template>
    </DataTable>
  </BaseModal>
</template>

<script>
import { currency } from '@/utils/helpers'
import { errorModal } from '@/modalMessages'

import DataTable from '@/components/DataTable'
import EntityHoverable from '@/components/iam/EntityHoverable'

import { getContactBids } from '@/services/contacts'

export default {
  name: 'ContactBids',
  components: {
    DataTable,
    EntityHoverable
  },
  props: {
    contactId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      loading: false,
      // The object bids includes keys to all the props that are needed in the DataTable, hence, we can use v-bind directly for clean code
      bids: {
        count: null,
        next: null,
        previous: null,
        results: []
      }
    }
  },
  computed: {
    headers () {
      return [
        { value: 'bid_date', text: 'Datum' },
        { value: 'property', text: 'Pand' },
        { value: 'property.address', text: 'Adres' },
        { value: 'amount', text: 'Bedrag' },
        { value: 'comment', text: 'Opmerking' }
      ]
    }
  },
  methods: {
    currency,
    show () {
      this.$refs.modal.show()
      this.loadContactBids()
    },
    hide () {
      this.$refs.modal.hide()
    },
    onHide () {
      this.bids = {
        count: null,
        next: null,
        previous: null,
        results: []
      }
    },

    async fetchContactBids (payload) {
      const response = await getContactBids(payload)
      return response.data
    },
    async loadContactBids () {
      try {
        this.loading = true
        const payload = { contactId: this.contactId }
        const bids = await this.fetchContactBids(payload)
        this.bids = bids
        return bids
      } catch (error) {
        errorModal('Fout bij het laden van de contactbiedingen, probeer het opnieuw.')
      } finally {
        this.loading = false
      }
    },
    async infiniteLoad ($infinite, next) {
      try {
        if (!next) {
          // No more data and state is loaded
          if (this.bids.results.length) $infinite.loaded()
          return $infinite.complete()
        }
        const bids = await this.fetchContactBids({ url: next })
        const results = [...this.bids.results, ...bids.results]
        this.bids = { ...bids, results }
        $infinite.loaded()
        return bids
      } catch (error) {
        $infinite.error()
        console.error(error)
      }
    }
  }
}
</script>
